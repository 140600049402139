import axios from "axios";
import Instance, { instance_public } from "helpers/Instance";

import jwtDecode from "jwt-decode";
import Utils from "@tteenbe/Utils";

class jwtService extends Utils.EventEmitter {
    init() {
        this.setInterceptors();
        this.handleAuthentication();
    }

    setInterceptors = () => {
        Instance.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                const originalRequest = error.config;
                if (
                    error?.response?.status === 401 &&
                    !originalRequest._retry
                ) {
                    originalRequest._retry = true;

                    this.refreshAccessToken();
                    return Instance(originalRequest);
                }
                if (error?.response?.status === 403) {
                    originalRequest._retry = false;
                    this.emit("onAutoLogout", "");
                }
                return Promise.reject(error);
            }
        );
    };

    refreshAccessToken = async () => {
        return await Instance.get("refresh").then(({ data }) => {
            if (data?.access_token) this.setSession(data.access_token);
        });
    };

    handleAuthentication = () => {
        let access_token = this.getAccessToken();

        if (!access_token) {
            return;
        }

        if (this.isAuthTokenValid(access_token)) {
            this.setSession(access_token);
            this.emit("onAutoLogin", true);
        } else {
            this.setSession(null);
            this.emit("onAutoLogout", "");
        }
    };

    createUser = (data) => {
        return new Promise((resolve, reject) => {
            Instance.post("register", data).then((response) => {
                if (response.data.data?.user && response.data.data.user) {
                    this.setSession(response.data.data.access_token);
                    resolve(response.data.data.user);
                } else {
                    reject(response.data.error);
                }
            });
        });
    };

    signInWithEmailAndPassword = (email, password) => {
        return new Promise((resolve, reject) => {
            Instance.post("login", {
                email,
                password,
            })
                .then((response) => {
                    if (response.data.success) {
                        this.setSession(response.data.data.access_token);
                        resolve(response?.data);
                    } else {
                        reject(response?.data);
                    }
                })
                .catch((error) => reject(error));
        });
    };

    signInWithToken = () => {
        return new Promise((resolve, reject) => {
            Instance.get("/access_token")
                .then(({ data }) => {
                    if (data.success) {
                        this.setSession(data.data.access_token);
                        resolve(data.data);
                    } else {
                        reject(data.error);
                    }
                })
                .catch((error) => reject(error));
        });
    };

    /////ACTUALIZACION DE DATOS DE VISTA THEME
    updateUserData = (user) => {
        return axios.post("/auth/user/update", {
            user: user,
        });
    };

    setSession = (access_token) => {
        if (access_token) {
            localStorage.setItem("user_id", access_token);
            Instance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${access_token}`;
            instance_public.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${access_token}`;
        } else {
            localStorage.removeItem("user_id");
            delete Instance.defaults.headers.common["Authorization"];
            delete instance_public.defaults.headers.common["Authorization"];
        }
    };

    logout = () => {
        this.setSession(null);
    };

    isAuthTokenValid = (access_token) => {
        if (!access_token) {
            return false;
        }
        const decoded = jwtDecode(access_token);
        const currentTime = Date.now() / 1000;

        if (decoded.exp < currentTime) {
            return false;
        } else {
            return true;
        }
    };

    getAccessToken = () => {
        return window.localStorage.getItem("user_id");
    };
}

const instance = new jwtService();

export default instance;
