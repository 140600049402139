import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER_ERROR,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    SET_USER_DATA,
    CLEAR_MESSAGE,
} from "../actions";

import jwtService from "../../services/jwtService/";

export const setUserData = (user) => ({
    type: SET_USER_DATA,
    payload: user,
});

export const loginUser = (user) => ({
    type: LOGIN_USER,
    payload: { user },
});
export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user,
});
export const loginUserError = (message) => ({
    type: LOGIN_USER_ERROR,
    payload: { message },
});

export const forgotPassword = (forgotUserMail, history) => ({
    type: FORGOT_PASSWORD,
    payload: { forgotUserMail, history },
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
    type: FORGOT_PASSWORD_SUCCESS,
    payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
    type: FORGOT_PASSWORD_ERROR,
    payload: message,
});

export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
    type: RESET_PASSWORD,
    payload: { resetPasswordCode, newPassword, history },
});
export const resetPasswordSuccess = (newPassword) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: newPassword,
});
export const resetPasswordError = (message) => ({
    type: RESET_PASSWORD_ERROR,
    payload: { message },
});

export const registerUser = (user, history) => ({
    type: REGISTER_USER,
    payload: { user, history },
});
export const registerUserSuccess = (payload) => ({
    type: REGISTER_USER_SUCCESS,
    payload,
});
export const registerUserError = (message) => ({
    type: REGISTER_USER_ERROR,
    payload: { message },
});

export const clearMessage = () => ({
    type: CLEAR_MESSAGE,
});

// export const logoutUser = (history) => ({
//   type: LOGOUT_USER,
//   payload: { history }
// });

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    history,
});

// export const logoutUserSuccess = (history) => {
export const logoutUserSuccess = () => {
    jwtService.logout();

    // history.push({
    //     pathname: "/user",
    // });

    return {
        type: LOGOUT_USER_SUCCESS,
    };
};

export const GET_USER_MENU_ADMIN = "[USER MENU ] GET";
export const GET_USER_MENU_ADMIN_SUCCESS = "[USER MENU ] GET SUCCESS";
export const GET_USER_MENU_ADMIN_ERROR = "[USER MENU ] GET ERROR";


export const getUserMenuSidebar = (payload) => ({
    type: GET_USER_MENU_ADMIN,
    payload
});
export const getUserMenuSidebarSuccess = (payload) => ({
    type: GET_USER_MENU_ADMIN_SUCCESS,
    payload,
});
export const getUserMenuSidebarError = (payload) => ({
    type: GET_USER_MENU_ADMIN_ERROR,
    payload,
});
