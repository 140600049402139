import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";

const createReducer = (asyncReducers) => {
    const appReducer = combineReducers({
        ...initReducer,
        ...asyncReducers,
    });

    return (state, action) => {
        if (action.type === "LOGOUT_USER SUCCESS") {
            state = undefined;
        }
        return appReducer(state, action);
    };
};
export const initReducer = {
    menu,
    settings,
};

export default createReducer;
