import {
	all,
	call,
	fork,
	put,
	// take,
	// takeEvery,
	takeLatest,
} from 'redux-saga/effects'
import jwtService from 'services/jwtService'

// import { auth } from 'helpers/Firebase';
import instance_public from 'helpers/Instance'
import { navigate } from '@reach/router'
import {
	LOGIN_USER,
	LOGOUT_USER,
	// REGISTER_USER,
	// FORGOT_PASSWORD,
	// RESET_PASSWORD,
} from '../actions'

import {
	loginUserSuccess,
	loginUserError,
	logoutUserSuccess,
	// registerUserSuccess,
	// registerUserError,
	// forgotPasswordSuccess,
	// forgotPasswordError,
	// resetPasswordSuccess,
	// resetPasswordError
} from './actions'

export function* watchLoginUser() {
	yield takeLatest(LOGIN_USER, loginWithEmailPassword)
}

function* loginWithEmailPassword({ payload }) {
	// const { email, password } = payload.user;
	const { user } = payload
	try {
		const perfil = yield call(loginWithEmailPasswordAsync, user)
		if (perfil?.success) {
			yield put(loginUserSuccess(perfil.data))
			navigate(`${perfil.data.ruta_principal}`)
		} else {
			yield put(loginUserError(perfil.message))
		}
	} catch (error) {
		yield put(loginUserError(error.message))
	}
}

// const loginWithEmailPasswordAsync = async ({email, password}) =>
//     await jwtService.signInWithEmailAndPassword(email, password)
//     .then((user) => user
//     )
//     .catch(error => error);
const loginWithEmailPasswordAsync = ({ email, password }) =>
	jwtService.signInWithEmailAndPassword(email, password)

// const loginWithEmailPasswordAsync = async (user) =>
//     await instance_public.post('login', user)
//         .then(authUser => authUser.data)
//         .catch(error => error);
// const loginWithEmailPasswordAsync = async (email, password) =>
//     await auth.signInWithEmailAndPassword(email, password)
//         .then(authUser => authUser)
//         .catch(error => error);

// export function* watchRegisterUser() {
//     yield takeEvery(REGISTER_USER, registerWithEmailPassword);
// }

// const registerWithEmailPasswordAsync = async (user) =>
//     await instance_public
//         .post("register", user)
//         .then((authUser) => authUser.data)
//         .catch((error) => error);
// // const registerWithEmailPasswordAsync = async (email, password) =>
// //     await auth.createUserWithEmailAndPassword(email, password)
// //         .then(authUser => authUser)
// //         .catch(error => error);

// function* registerWithEmailPassword({ payload }) {
//     // const { email, password, name } = payload.user;
//     const { history } = payload;
//     try {
//         const registerUser = yield call(
//             registerWithEmailPasswordAsync,
//             payload.user
//         );
//         if (registerUser.success) {
//             yield put(registerUserSuccess(registerUser.message));
//             history.push("/user/login");
//         } else {
//             yield put(registerUserError(registerUser.message));
//         }
//     } catch (error) {
//         yield put(registerUserError(error));
//     }
// }

export function* watchLogoutUser() {
	yield takeLatest(LOGOUT_USER, logoutUser)
}

// const logoutAsync = async () => {
const logoutAsync = () => {
	instance_public.get('logout')
}

// function* logoutUser({ history }) {
function* logoutUser() {
	try {
		yield call(logoutAsync)
		// yield put(logoutUserSuccess(history));
		yield put(logoutUserSuccess())
		navigate(`/user`)
	} catch (error) {}
}

/////////////////////////////////////////////////////////////////////////////////////////////
// export function* watchForgotPassword() {
//     yield takeEvery(FORGOT_PASSWORD, forgotPassword);
// }

// const forgotPasswordAsync = async (email) => {
//     return await instance_public.post("forgot-password", { email });
//     // .then(authUser => authUser)
//     // .catch(error => error);
// };
// // const forgotPasswordAsync = async (email) => {
// //     return await auth.sendPasswordResetEmail(email)
// //         .then(user => user)
// //         .catch(error => error);
// // }

// function* forgotPassword({ payload, history }) {
//     const { email } = payload.forgotUserMail;
//     try {
//         const data = yield call(forgotPasswordAsync, email);
//         if (data.success) {
//             yield put(forgotPasswordSuccess(data.data.message));
//             history.push("/user/login");
//         } else {
//             yield put(forgotPasswordError(data.data.message));
//         }
//     } catch (error) {
//         yield put(forgotPasswordError(error));
//     }
// }

////////////////////////////////////////////////////////////////////////////////////////////
// export function* watchResetPassword() {
//     yield takeEvery(RESET_PASSWORD, resetPassword);
// }

// const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
//     return await auth.confirmPasswordReset(resetPasswordCode, newPassword)
//         .then(user => user)
//         .catch(error => error);
// }

// function* resetPassword({ payload }) {
//     const { newPassword, resetPasswordCode } = payload;
//     try {
//         const resetPasswordStatus = yield call(resetPasswordAsync, resetPasswordCode, newPassword);
//         if (!resetPasswordStatus) {
//             yield put(resetPasswordSuccess("success"));
//         } else {
//             yield put(resetPasswordError(resetPasswordStatus.message));
//         }
//     } catch (error) {
//         yield put(resetPasswordError(error));

//     }
// }

export default function* rootSaga() {
	yield all([
		fork(watchLoginUser),
		fork(watchLogoutUser),
		// fork(watchRegisterUser),
		// fork(watchForgotPassword),
		// fork(watchResetPassword),
	])
}
