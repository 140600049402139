export const url = process.env.REACT_APP_API_URL
export const urlImage = process.env.REACT_APP_API_URL

// export const url = 'http://localhost:8000'
// export const urlImage = 'http://localhost:8000'

// export const url = '//samyspa.com'
// export const urlImage = '//samyspa.com'

// export const url_real_state_inmueble = '';

// // ; cPanel-generated php ini directives, do not edit
// // ; Manual editing of this file may result in unexpected behavior.
// // ; To make changes to this file, use the cPanel MultiPHP INI Editor (Home >> Software >> MultiPHP INI Editor)
// // ; For more information, read our documentation (https://go.cpanel.net/EA4ModifyINI)

// // allow_url_fopen = Off
// // allow_url_include = Off
// // display_errors = Off
// // enable_dl = Off
// // file_uploads = On
// // max_execution_time = 120
// // max_input_time = 500
// // max_input_vars = 1000
// // memory_limit = 502M
// // post_max_size = 100M
// // session.gc_maxlifetime = 1440
// // session.save_path = "/var/cpanel/php/sessions/ea-php73"
// // upload_max_filesize = 100M
// // zlib.output_compression = On
