import 'assets/css/bootstrapCustom.scss'
import 'react-image-lightbox/style.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-alice-carousel/lib/alice-carousel.css'

import {
	isMultiColorActive,
	defaultColor,
	themeColorStorageKey,
	isDarkSwitchActive,
} from 'constants/defaultValues'

const color =
	(isMultiColorActive || isDarkSwitchActive) &&
	localStorage.getItem(themeColorStorageKey)
		? localStorage.getItem(themeColorStorageKey)
		: defaultColor

localStorage.setItem(themeColorStorageKey, `${color}`)

let render = () => {
	import('assets/css/sass/themes/gogo.' + color + '.scss').then(() => {
		require(/* webpackChunkName: "AppRenderer" */ './AppRenderer')
	})
}

render()
