import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "redux/store";
import reportWebVitals from "reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import rootSagaAuthUser from "./redux/auth/saga";

const store = configureStore();
const App = React.lazy(() =>
    import("redux/auth/reducer").then((module) => {
        store.injectReducer("authUser", module.default);
        store.injectSaga("authUser", rootSagaAuthUser);
        return import(/* webpackChunkName: "AppInicio" */ "./App");
    })
);

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <Suspense
                fallback={
                    <div style={{ height: "100vh" }}>
                        <div className="loading" />
                    </div>
                }
            >
                <App />
            </Suspense>
        </Provider>
    </BrowserRouter>,
    document.getElementById("root")
    // </React.StrictMode>
);

reportWebVitals();
