import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import createReducers from "./reducers";
import sagas from "./sagas";
import reduxCatch from "redux-catch";
// import { instance_public } from "../helpers/Instance";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

function errorHandler(error, getState, lastAction, dispatch) {
    // // console.error(error);
    // console.debug("current state", getState());
    // console.debug("last action was", lastAction);
    // // optionally dispatch an action due to the error using the dispatch parameter
}

export const myMiddleware = (store) => (next) => (action) => {
    return next(action);
};

const composeEnhancers =
    process.env.NODE_ENV !== "production" &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;

const enhancer = composeEnhancers(
    applyMiddleware(...middlewares, reduxCatch(errorHandler), myMiddleware)
);

export function createSagaInjector(runSaga, rSagas) {
    const injectedSagas = new Map();
    const isInjected = (key) => injectedSagas.has(key);
    const injectSaga = (key, saga) => {
        if (isInjected(key)) return;
        const task = runSaga(saga);
        injectedSagas.set(key, task);
    };
    injectSaga("root", rSagas);
    return injectSaga;
}

const store = createStore(createReducers(), enhancer);

store.asyncReducers = {};

const configureStore = () => {
    store.injectSaga = createSagaInjector(sagaMiddleware.run, sagas);

    store.injectReducer = (key, asyncReducer) => {
        // if (store.asyncReducers[key]) return;
        store.asyncReducers[key] = asyncReducer;
        store.replaceReducer(createReducers(store.asyncReducers));
    };

    return store;
};

export function getStore() {
    return store;
}

export default configureStore;
