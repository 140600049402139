import axios from 'axios'
import { url } from '../localUrl'

const instance = axios.create({
	baseURL: `${url}/api/v1/`,
	timeout: 1000 * 30,
	xsrfCookieName: 'XSRF-TOKEN',
	xsrfHeaderName: 'X-XSRF-TOKEN',
	onUploadProgress: () => {},
})

instance.interceptors.request.use(
	async function (config) {
		return config
	},
	function (error) {
		return Promise.reject(error)
	}
)

const instance_p = axios.create({
	baseURL: `${url}/v1/`,
	timeout: 1000 * 30,
})

instance_p.interceptors.request.use(
	async function (config) {
		return config
	},
	function (error) {
		return Promise.reject(error)
	}
)

export const instance_public = instance_p
export default instance
